<template>
    <div>
        <div class="ft20 cl-main ftw600">服务项目库</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <a-form-item label="平台项目名称">
                        <a-input v-model="search.name" placeholder="请输入平台项目名称"></a-input>
                    </a-form-item>

                    <a-form-item label="项目分类">
                        <div>
                            <a-tree-select v-model="search.project_type_id" style="width: 200px"
                                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="type_list"
                                           placeholder="请选择" >
                            </a-tree-select>
                        </div>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div class="mt30">
                        <a-radio-group v-model="search.status" button-style="solid" >
                            <a-radio-button :value="1">
                                上架中
                            </a-radio-button>

                        </a-radio-group>
                    </div>

                    <div class="mt20">
                        <div class="wxb-table-gray">
                            <a-table rowKey="project_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div class="flex alcenter " slot="project" slot-scope="record">
                                    <img :src="record.cover_img"  class="projetc-index-cover-img" />
                                    <div class="ml5" style="text-align: left;">
                                        <div>{{record.name}}</div>
                                    </div>
                                </div>

                                <div class="flex alcenter center" slot="type" slot-scope="type,record">
                                    {{type != null ? type.name : '暂无分类'}}
                                </div>

                                <div class="flex alcenter center" slot="price" slot-scope="price,record">
                                    ¥{{price}}
                                </div>

                                <div class="flex alcenter center" slot="market_price" slot-scope="market_price,record">
                                    ¥{{market_price}}
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-button v-if="record.add_project_count==0" @click="selectProjectAct(record)"  type="primary">
                                            选用
                                        </a-button>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {listMixin} from '../../common/mixin/list.js';
    export default{
        mixins: [listMixin],
        data(){
            return{
                loading: false,
                pagination: {
                    current: 1,
                    pageSize: 10, //每页中显示10条数据
                    total: 0,
                },
                type_list:[],
                search:{
                    name:'',
                    project_type_id:undefined,
                    status:1,
                },
                columns: [

                    {title: '项目编号',dataIndex: 'project_id',align: 'center'},
                    {title: '项目名称',key: 'project',align: 'left',width:300,scopedSlots: {customRender: 'project'}},
                    {title: '所属分类',dataIndex: 'type',align: 'center',scopedSlots: {customRender: 'type'}},
                    {title: '会员价',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
                    {title: '项目门市价',dataIndex: 'market_price',align: 'center',scopedSlots: {customRender: 'market_price'}},
                    {title: '是否选用',dataIndex: 'add_project_text',align: 'center',ellipsis: true},
                    {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
                ],
                datas: [],
            }
        },
        methods:{
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('admin/getPlatformProjectList',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                    project_type_id:this.search.project_type_id,
                    status:this.search.status,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    if(res.type_list){
                        this.type_list=res.type_list;
                    }
                    this.loading=false;
                }).catch(res=>{
                    this.loading=false;
                })
            },

            selectProjectAct(record){
                this.$confirm({
                    title:'确认选用这个项目吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('admin/selectProject',{
                                project_id:record.project_id,
                            }).then(res=>{
                                this.$message.success('已成功选取项目');
                                this.getLists();
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },


            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },
        }
    }
</script>

<style>
    .projetc-index-cover-img{
        width: 80px;
        height: 60px;
    }
</style>
